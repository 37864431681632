export const LOAD_APP = "LOAD_APP";
export const PUSH_NEW_APP = "PUSH_NEW_APP";
export const NOTIFICATION_ON = "NOTIFICATION_ON";
export const NOTIFICATION_OFF = "NOTIFICATION_OFF";


export const T_STATUS_SUCCESS = "T_STATUS_SUCCESS";
export const T_STATUS_ERROR = "T_STATUS_ERROR";
export const T_STATUS_LOADING = "T_STATUS_LOADING";
export const T_STATUS_CLOSE_LOADING = "T_STATUS_CLOSE_LOADING";
export const T_STATUS_WARNING = "T_STATUS_WARNING";
export const T_STATUS_INFO = "T_STATUS_INFO";


export const T_STATUS_SHOW = "T_STATUS_SHOW";


export const SET_NEW_FONT = "SET_NEW_FONT";
export const SET_NEW_MENU_KEY = "SET_NEW_MENU_KEY";









export const LOAD_PARTY = "LOAD_PARTY";
export const ADD_PARTY = "ADD_PARTY";
export const EDIT_PARTY = "EDIT_PARTY";









export const ACCESS = "ACCESS";



export const GET_MY_DETAILS = "GET_MY_DETAILS";
export const GET_MY_LEAVE_BALANCE = "GET_MY_LEAVE_BALANCE";
export const GET_MY_LEAVE = "GET_MY_LEAVE";
export const GET_MY_ATTENDANCE = "GET_MY_ATTENDANCE";
export const GET_HOLIDAY = "GET_HOLIDAY";
export const GET_NOTICE = "GET_NOTICE";
export const SOCKET_ON = "SOCKET_ON";
export const CHATBOX = "CHATBOX";
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_PAYHEAD = "GET_PAYHEAD";
export const GET_SESSION = "GET_SESSION";







export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE";

